import revive_payload_client_4sVQNw7RlN from "/home/library/www/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/library/www/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/library/www/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/library/www/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/library/www/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_RWR0bN8u4F from "/home/library/www/frontend/.nuxt-haltefest/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/library/www/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/home/library/www/frontend/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import language_Zy8KLHw9oT from "/home/library/www/frontend/node_modules/nuxt-language-negotiation/dist/runtime/plugins/language.mjs";
import provideState_6DkFrUCTO4 from "/home/library/www/frontend/node_modules/nuxt-graphql-middleware/dist/runtime/plugins/provideState.mjs";
import chunk_reload_client_UciE0i6zes from "/home/library/www/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import useDirectives_W0tXrzvhuP from "/home/library/www/frontend/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/useDirectives.mjs";
import createBootstrap_ywvW1KIeJ9 from "/home/library/www/frontend/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/createBootstrap.mjs";
import graphqlMiddleware_2xI7SMabjg from "/home/library/www/frontend/plugins/graphqlMiddleware.ts";
import texts_P4dMAr3Ct0 from "/home/library/www/frontend/node_modules/nuxt-easy-texts/dist/runtime/plugins/texts.mjs";
import drupalUser_HxAWH89yLt from "/home/library/www/frontend/plugins/drupalUser.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_RWR0bN8u4F,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  language_Zy8KLHw9oT,
  provideState_6DkFrUCTO4,
  chunk_reload_client_UciE0i6zes,
  useDirectives_W0tXrzvhuP,
  createBootstrap_ywvW1KIeJ9,
  graphqlMiddleware_2xI7SMabjg,
  texts_P4dMAr3Ct0,
  drupalUser_HxAWH89yLt
]